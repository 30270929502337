export default {
  proxy_path: "https://9y0ujjfpt5.execute-api.us-east-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://80txy9b1zk.execute-api.us-east-2.amazonaws.com/api",
  google_api_key: "AIzaSyAVCuQtOaN6jrjHl89-Gi6FS2MKXHMOIwE",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.vale.forwoodsafety.com",
  forwood_id_url: "https://id.staging.vale.forwoodsafety.com?redirect_uri=https://mapreport.staging.vale.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.vale.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-2_uNxxRpKGT",
    userPoolWebClientId: "2amup6iu3hdb4c88ubal3r2s86",
    cookieStorage: {
      domain: ".staging.vale.forwoodsafety.com",
      secure: true
    }
  }
};
